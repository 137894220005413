<template>
	<div class="questionList" ref="list">
		<a-spin :spinning="loading">
			<div :style="questionHide ?'display: none;' :'display: block;'">
			<div class="addBtns" :style="'left:' + left + 'px'">
				<div class="btn" @click="add(item)" v-for="(item,idx) of types" :key='idx'>
					{{item.label}}
				</div>
			</div>
            <div v-for="(item,idx) of questions" :key='idx' :ref="'q' + idx">
                <div class="questionContainer" v-if="item.show">
                    <question width='382' :type="item.type" :title="item.title || '标题'" :isMust="item.isMust"
                        :direction="item.direction" :no="item.no" :options.sync="item.options" class="question" :min='item.min'
                        :max='item.max' :minText='item.minText' :maxText='item.maxText' :rateStyle='item.rateStyle' :isEdit='true' :hasMap="hasMap">
                    </question>
                    <question-editor :idx='idx' class="questionEditor" :type='item.type' :title.sync='item.title'
                        :direction.sync='item.direction' :isMust.sync='item.isMust' :options.sync='item.options' :hideMapOption='mappingFlag == 1 && idx == 0'
                        @btnClick='btnClick' :min.sync='item.min' :max.sync='item.max' :minText.sync='item.minText'
                        :showMap='(mappingFlag == 1 && hasMap && idx == 0) || (mappingFlag == 1 && !hasMap)' @mapChange='mapChange'
                        :maxText.sync='item.maxText' :isMap.sync='item.isMap' :rateStyle.sync='item.rateStyle' :isEdit='item.isEdit' :showDel='item.showDel'>
                    </question-editor>
                </div>
            </div>
			<div class="bottomBtn" v-if="!questionHide">
				<div v-if="editFlag == 1" @click="sub(0)">暂存并预览</div>
                <div @click="sub(1)">提交</div>
				<div @click="delShow = true">取消</div>
			</div>
			</div>
		</a-spin>
		<message-modal
		    :visible.sync="msgShow"
		    :info ="msg"
			@deleteHandOk="hide()"
		></message-modal>
		<delete-modal
		  :visible.sync="delShow"
		  info="是否确认取消?"
		  @deleteHandOk="confirmCalcle()"
		></delete-modal>
		<delete-modal
		  :visible.sync="delItemShow"
		  info="删除题目可能影响平均分的统计。"
		  @deleteHandOk="delItem()"
		></delete-modal>
	</div>
</template>
<script>
	/**
	 * @Author: Jenos
	 * @Date: 2021-09-26 09:00
	 * @LastEditTime: 2021-09-17 15:08
	 * @Description: 问卷容器
	 */
	import Question from '@/views/questionnaire/components/Question';
	import QuestionEditor from '@/views/questionnaire/components/QuestionEditor';
	import {
		subQuestionnaire,
		getQuestionnaire,
		getQuestionTypes
	} from "./api";
	let that;
	export default {
		name: "question-container",
		components: {
			Question,
			QuestionEditor,
		},
		data() {
			return {
				left: 0,
				id: '',
				title: '',
				editFlag: 1, // (1新增  0编辑)
				mappingFlag: 0, // 是否展示使用映射关系作为下拉)  1是 0否
				account: '', // 当前商家登录的账号
				questions: [],
				types: [],
				loading: false,
				hasMap: false,//已有映射关系的题目
				msgShow: false,
				msg: '',
				delShow: false,
				questionHide: false,
				delItemShow: false,
				delIdx: -1,
			};
		},
		props: {},
		watch: {
			msgShow(value) {
				if(!value) {
					this.questionHide = true;
				}
			}
		},
		computed: {},
		created() {},
		mounted() {
			that = this;
			this.getTypes();
			this.left = this.$refs.list.getBoundingClientRect().right - 100;
			this.id = this.getQueryValue('questionId')
			this.title = this.getQueryValue('title')
			this.editFlag = this.getQueryValue('editFlag')
			this.mappingFlag = this.getQueryValue('mappingFlag')
			if(this.mappingFlag == 0 && this.editFlag == 1) {
				this.add({type: 'radio',code: 701})
			}
			if(this.mappingFlag == 1 && this.editFlag == 1) {
				this.add({type: 'select',code:703})
				this.questions[0].isMap = true;
				this.questions[0].isMust = true;
				this.hasMap = true;
			}
			if(this.mappingFlag == 1 && this.editFlag == 0) {
				this.hasMap = true;
			}
			// this.account = this.getQueryValue('account')
			if (this.editFlag == 0) {
				this.getQuestionnaire();
			}

		},
		methods: {
			mapChange(e) {
				this.questions[e.idx].isMap = e.value;
				this.hasMap = e.value;
				this.first(e.idx);
				this.$nextTick(() => {
					window.scrollTo({
						behavior: 'smooth',
						top: this.$refs['q0'][0].offsetTop
					})
				})
			},
			btnClick(e) {
				this.loading = true;
				switch (e.val) {
					case 'del':
						that.del(e.idx)
						break;
					case 'up':
						that.up(e.idx)
						break;
					case 'down':
						that.down(e.idx)
						break;
					case 'first':
						that.first(e.idx)
						break;
					case 'last':
						that.last(e.idx)
						break;
				}
				this.loading = false;
			},

			del(idx) {
				this.delItemShow = true;
				this.delIdx = idx;
				// this.$confirm({
				// 	title: '是否确认删除',
				// 	okText: '删除',
				// 	okType: 'danger',
				// 	cancelText: '取消',
				// 	onOk() {
				// 		that.$delete(that.questions, idx)
				// 	}
				// });
			},
			delItem() {
				that.$delete(that.questions, this.delIdx)
                this.getNo();
			},
			up(idx) {
				if (idx == 0) return;
				this.loading = true;
				let arr = this.questions;
				arr[idx] = arr.splice(idx - 1, 1, arr[idx])[0];
                this.getNo();
				this.loading = false;
			},
			down(idx) {
				this.loading = true;
				let arr = this.questions;
				arr[idx] = arr.splice(idx + 1, 1, arr[idx])[0];
                this.getNo();
				this.loading = false;
			},
			first(idx) {
				this.loading = true;
				let item = this.questions.splice(idx, 1)[0];
				this.questions.unshift(item);
                this.getNo();
				this.loading = false;
			},
			last(idx) {
				this.loading = true;
				let item = this.questions.splice(idx, 1)[0];
				this.questions.push(item);
                this.getNo();
				this.loading = false;
			},
			numChange(e) {
				if (e.type == 'min') {
					this.questions[e.idx].min = Number(e.val);
				}
				if (e.type == 'minText') {
					this.questions[e.idx].minText = e.val;
				}
				if (e.type == 'max') {
					this.questions[e.idx].max = Number(e.val);
				}
				if (e.type == 'maxText') {
					this.questions[e.idx].maxText = e.val;
				}
			},
			add(type) {
				let title = '';
				switch (type.type) {
					case 'radio':
						title = '单选题'
						break;
					case 'checkbox':
						title = '多选题'
						break;
					case 'select':
						title = '下拉框'
						break;
					case 'input':
						title = '填空题'
						break;
					case 'textarea':
						title = '简答题'
						break;
					case 'slider':
						title = '评分题'
						break;
					case 'rate':
						title = '量表题'
						break;
				}
				let question = {
					type: type.type,
					title,
					isMust: false,
					isMap: false,
					direction: '',
					min: 0,
					max: 0,
                    show: true,
                    no: this.questions.length == 0 ?1 :this.questions[this.questions.length-1].no + 1,
					isEdit: false,
					minText: '不满意',
					maxText: '满意',
					typeId: type.code,
					style: 'star',
					rateStyle: 'star',
					options: [{
						label: '',
						img: '',
						isFill: false,
						isMust: false,
						score: 0,
                        edit: true,
					}]
				};
				if (type.type == 'checkbox') {
					question.max = 99999;
					question.max = 99999;
				}
				if (type.type == 'slider') {
					question.max = 100;
				}
				if (type.type == 'rate') {
					question.options = [{
							label: '很不满意',
							img: '',
							isFill: false,
							score: 1
						},
						{
							label: '不满意',
							img: '',
							isFill: false,
							score: 2
						},
						{
							label: '一般',
							img: '',
							isFill: false,
							score: 3
						},
						{
							label: '满意',
							img: '',
							isFill: false,
							score: 4
						},
						{
							label: '很满意',
							img: '',
							isFill: false,
							score: 5
						}
					]
				};
				this.questions.push(question);
				let i = this.questions.length - 1;
				this.$nextTick(() => {
					window.scrollTo({
						behavior: 'smooth',
						top: this.$refs['q' + i][0].offsetTop
					})
				})
			},
			cancle() {
				this.delShow = true;
				this.msg = '问卷已提交请关闭页面'
				
				// this.$confirm({
				// 	title: '是否确认取消',
				// 	okText: '确认',
				// 	okType: 'danger',
				// 	cancelText: '关闭',
				// 	onOk() {
						
				// 	}
				// });
			},
			confirmCalcle() {
				that.getQuestionnaire();
				this.msgShow = true;
				this.msg = '请关闭页面'
			},
            /**
             *  status 0草稿（对应暂存并生成预览按钮）  1未发布（对应提交按钮）
             */
			sub(status) {
				if (!this.beforeSub()) return
				let subList = this.parseQuestions(this.questions);
				let data = {
					id: this.id,
					title: this.title,
                    status,
					questionSubDtoList: subList
				};
				this.loading = true;
				subQuestionnaire(data).then(res => {
					this.loading = false;
					if (res.data.type == 'success') {
                        if(status == 1) {
                            this.$message.success(res.data.text);
                            this.msgShow = true;
                            this.msg = '问卷已提交请关闭页面'
                            parent.toList();
                        } else {
                            this.$message.success(res.data.text);
                            this.msgShow = true;
                            this.msg = '问卷已提交请关闭页面'
                            let newpage = this.$router.resolve({ 
                                name: 'questionnaireEditorPreview',
                                query:{
                                    questionId:this.id
                                }   
                            })
                            window.open(newpage.href, '_blank');
                        }
						
					} else if (res.data.code == 400) {
						// this.$message.warning(res.data.msg)
					} else{
						this.$message.warning(res.data.msg)
					}
				})
			},
			beforeSub() {
				if (this.questions == 0) {
					this.$message.warning('请添加后提交');
					return false;
				}
				for (let i = 0; i < this.questions.length; i++) {
					let item = this.questions[i]
					if (!item.title) {
						let msg = `请输入第${i+1}题目标题`;
						this.$message.warning(msg);
						this.$nextTick(() => {
							window.scrollTo({
								behavior: 'smooth',
								top: this.$refs['q' + i][0].offsetTop
							})
						})
						return false
					}
					if (item.type == 'slider') {
						if (item.min<0) {
							let msg = `请输入第${i+1}题最小值`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false
						}
						if (!item.max) {
							let msg = `请输入第${i+1}题最大值`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false
						}
						if (!item.minText) {
							let msg = `请输入第${i+1}题最小值显示文本`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false
						}
						if (!item.maxText) {
							let msg = `请输入第${i+1}题最大值显示文本`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false
						}
					}
					if(item.type == 'checkbox') {
						if(item.min == 99999) {
							let msg = `请选择第${i+1}题至少选几项`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false
						}
						if(item.max == 99999) {
							let msg = `请选择第${i+1}题至多选几项`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false
						}
					}
					for (let o = 0; o < item.options.length; o++) {
						let option = item.options[o];
						if (item.type != 'input' && item.type != 'textarea' && item.type != 'slider' && !option.label && (
								item.type == 'select' && !item.isMap)) {
							let msg = `请输入第${i+1}题目选项文字`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							});
							return false
						}
						if (item.type == 'rate' && option.score < 0) {
							let msg = `请输入第${i+1}题目选项分数`;
							this.$message.warning(msg);
							this.$nextTick(() => {
								window.scrollTo({
									behavior: 'smooth',
									top: this.$refs['q' + i][0].offsetTop
								})
							})
							return false
						}
					}
				}
				return true
			},
			// 提交时解析成需要的数据
			parseQuestions(questions) {
				let subList = [];
				questions = questions.filter(el => el.isDel != '1')
				for (let i = 0; i < questions.length; i++) {
					let item = questions[i];
					let subItem = {
						explain: item.direction,
						id: item.id || null,
						isMappingSelect: item.isMap ? '1' : '0',
						isRequire: item.isMust ? '1' : '0',
						minSelect: item.min,
						maxSelect: item.max,
						orderBy: i + 1,
						style: item.rateStyle,
						title: item.title,
						typeId: item.typeId,
					};
					let subItemDtoList = [];
					// if(item.isMap) {
					// 	item.options = [];
					// };
					if (item.type != 'slider') {
						for (let o = 0; o < item.options.length; o++) {
							let option = item.options[o];

							let data = {
								// isRequire: '0',
								fraction: option.score,
								id: option.id || null,
								isBlank: option.isFill ? '1' : '0',
								isRequire: option.isMust ? '1' : '0',
								optionExplain: option.label,
								orderBy: o + 1,
								imageUrl: option.img || ''
							};
							subItemDtoList.push(data)
						}
					} else {
						subItemDtoList.push({
							id: item.subItemDtoList ?item.subItemDtoList[0].id : null,
							isBlank: '0',
							min: item.min,
							minExplain: item.minText,
							max: item.max,
							maxExplain: item.maxText,
						})
					}
					subItem.subItemDtoList = subItemDtoList;
					subList.push(subItem);
				};
				return subList
			},
			getQuestionnaire() {
				let data = {
					questionId: this.id,
					// account: this.account
					isEdit: 'Y'
				};
				this.loading = true;
				getQuestionnaire(data).then(res => {
					if(res.data.code == 400) {
						this.$message.warning(res.data.msg);
						this.loading = false;
						return;
					}
					let questions = res.data.questionSubDtoList;
					// questions[0].typeId='701'
					// questions[1].typeId='702'
					// questions[2].typeId='703'
					// questions[3].typeId='704'
					// questions[4].typeId='705'
					// questions[5].typeId='706'
					// questions[6].typeId='706'
					// questions[7].typeId='707'
					questions && questions.sort((a, b) => {
						return a.orderBy - b.orderBy
					})
                    let no = 0;
					for (let item of questions) {
                        item.show = true;
                        no++
                        if(item.isDel == 1) {
                            item.show = false;
                            no--;
                        }
                        item.no = no;
						let typeId = parseInt(item.typeId);
						switch (typeId) {
							case 701:
								item.type = 'radio'
								break;
							case 702:
								item.type = 'checkbox'
								break;
							case 703:
								item.type = 'select'
								break;
							case 704:
								item.type = 'input'
								break;
							case 705:
								item.type = 'textarea'
								break;
							case 707:
								item.type = 'slider';
								item.min = item.subItemDtoList[0].min
								item.minText = item.subItemDtoList[0].minExplain
								item.max = item.subItemDtoList[0].max
								item.maxText = item.subItemDtoList[0].maxExplain
								item.options = [];
								break;
							case 706:
								item.type = 'rate'
								break;
						}
						item.isMust = item.isRequire == 1;
						item.isMap = item.isMappingSelect == 1;
						item.direction = item.explain;
						item.rateStyle = item.style;
						item.isEdit = true;
                        // item.showDel = false;
						if (item.typeId != '707') {
							item.min = item.minSelect;
							item.max = item.maxSelect;
							let options = item.subItemDtoList;
							for (let option of options) {
								option.label = option.optionExplain;
								option.img = option.imageUrl || '';
								option.isFill = option.isBlank == 1;
								option.isMust = option.isRequire == 1;
								option.score = option.fraction || 0;
							}
							options&& options.sort((a, b) => {
								return a.orderBy - b.orderBy
							})
							item.options = options;
						}
					}
					this.questions = questions;
					this.loading = false;
				})
			},
			getQueryValue(queryName) {
				let reg = `(^|&)${queryName}=([^&]*)(&|$)`
				let r = window.location.hash.substr(22).match(reg);
				if (r != null) return unescape(r[2]);
				return null;
			},
			getTypes() {
				let data = {
					pid: 14186619232
				}
				getQuestionTypes(data).then(res => {
					let types = [];
					for (let item of res.data.list) {
						let type = {};
						type.code = item.code;
						type.label = item.name
						switch (item.code) {
							case '701':
								type.type = 'radio'
								break;
							case '702':
								type.type = 'checkbox'
								break;
							case '703':
								type.type = 'select'
								break;
							case '704':
								type.type = 'input'
								break;
							case '705':
								type.type = 'textarea'
								break;
							case '707':
								type.type = 'slider'
								break;
							case '706':
								type.type = 'rate'
								break;
						}
						types.push(type);
					};
					this.types = types
				})
			},
            getNo() {
                let no = 0;
                for (let item of this.questions) {
                    no++
                    if(item.isDel == 1) {
                        no--;
                    }
                    item.no = no;
                }
            }

		},
	};
</script>

<style lang="less" scoped>
	.questionList {
		padding-left: 24px;
		padding-top: 30px;
		padding-bottom: 30px;
		margin: 16px 0 40px;
		box-sizing: border-box;
		position: relative;
	}

	.questionContainer {
		width: 1107px;
		margin-bottom: 24px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.09);
		border-top: 1px solid #00AAA6;
		display: flex;
		padding: 22px 24px;
		box-sizing: border-box;
	}

	.question {
		width: 382px;
		margin-right: 24px;
	}

	.questionEditor {
		flex: 1;
		border-left: 1px dotted #CCCCCC;
	}

	.addBtns {
		position: fixed;
		width: 80px;
		top: 200px;
		z-index: 99999;
		.btn {
			width: 100%;
			height: 34px;
			text-align: center;
			line-height: 34px;
			background: #EEEEEE;
			border-radius: 4px;
			font-size: 16px;
			font-weight: 400;
			color: #777777;
			margin-bottom: 8px;
			transition: all .3s;
			cursor: pointer;
		}

		.btn:hover {
			background-color: #00AAA6;
			color: #fff;
		}
	}

	.bottomBtn {
		display: flex;
		justify-content: center;
		align-items: center;

		div {
			width: 95px;
			height: 40px;
            margin-right: 24px;
			text-align: center;
			line-height: 40px;
			background-color: #00AAA6;
			font-size: 16px;
			font-weight: 400;
			color: #FFFFFF;
			border-radius: 4px;
			cursor: pointer;
		}

		div:last-child {
			background-color: #AAAAAA;
			margin-right: 0;
		}
	}
</style>
