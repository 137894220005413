
<template>
	<div class="main">
		<question-container></question-container>
	</div>
</template>

<script>
	import QuestionContainer from '@/views/questionnaire/components/QuestionContainer';
	export default {
	    name: "questionnaireEditor",
	    components: {
			QuestionContainer
	    }
	};
	
</script>
<style lang="less" scoped></style>
