<template>
	<div class="options">
		<div class="optionTitle">
			<div class="text">
				选项文字
			</div>
			<div class="img" v-if="type != 'select' && type != 'rate'">
				图片
			</div>
			<div class="score" v-if="type == 'rate'" style="margin-left: 10px;margin-right: 15px;">
				分数
			</div>
			<div class="updown" :style="type =='select' && 'margin-left:5px;'">
				上移下移
			</div>
			<div class="fill" v-if="type != 'select' && type != 'rate'">
				允许填空
			</div>
			<div class="fill" style="text-align: center;" v-if="type != 'select' && type != 'rate'">
				必填
			</div>
		</div>
		<div class="con">
			<div class="option" v-for="(item,idx) of optionsTemp" :key='idx'>
				<div class="optionCon">
					<div class="text" :style="(type =='select' || type =='rate') && 'margin-right:0;'">
						<a-input :maxLength='20' type="text" placeholder="最多20个字符" v-model='item.label' @change='labelChange(item.label)' />
						<img @click="add(idx)" src='@/assets/icon/add.png' />
						<img v-if="options.length != 1" @click="minus(idx)" src='@/assets/icon/minus.png' />
						<img v-if="options.length == 1" src='@/assets/icon/minus2.png' />
					</div>
					<div class="img" v-if="type != 'select' && type != 'rate'">
						<img src='@/assets/icon/img.png' @click="chooseImg(idx)" />
					</div>
					<div class="score" v-if="type == 'rate'">
						<a-input v-model='item.score' @input="item.score=item.score.replace(/[^\d]/g,'')" />
					</div>
					<div class="updown">
						<img v-if="idx != 0" @click='up(idx)' src='@/assets/icon/up3.png' />
						<img v-if="idx == 0" src='@/assets/icon/up4.png' />
						<img v-if="options.length > 1 && idx != options.length -1" @click="down(idx)"
							src='@/assets/icon/down3.png' />
						<img v-if="options.length == 1 || idx == options.length -1" src='@/assets/icon/down4.png' />
					</div>
					<div class="fill"  @click="setIdx(idx)" v-if="type != 'select' && type !='rate'">
						<a-checkbox @change="isFillChange" :checked='item.isFill' :data-idx="idx"></a-checkbox>
					</div>
					<div class="fill" @click="setIdx(idx)" style="text-align: center;" v-if="type != 'select' && type !='rate'">
						<a-checkbox @change="isMustChange" :disabled='!item.isFill' :checked='item.isMust' :data-idx="idx"></a-checkbox>
					</div>
				</div>
				<div class="warn" v-show="item.label.length >= 20">
					输入超出最大限制，最多输入20个字符
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	/**
	 * @Author: Jenos
	 * @Date: 2021-09-17 15:08
	 * @LastEditTime: 2021-09-17 15:08
	 * @Description: 
	 */
	import {
		publicPath
	} from "@/common/constant.js";
	import {
		upload
	} from "./api";
	let that;
	export default {
		name: "options",
		data() {
			return {
				uploadUrl: publicPath + "/comm/method=uploadFile.nd",
				fileList: [],
				idx: -1,
				optionsTemp: [{
					label: '',
					img: '',
					isFill: false,
					isMust: true,
					score: ''
				}]
			};
		},
		props: {
			type: {
				type: String,
				default: ''
			},
			options: {
				type: Array,
			}
		},
		watch: {
			optionsTemp(val) {
				this.$emit("update:options", val);
			},
			options(val) {
				this.optionsTemp = this.options
			},

		},
		computed: {},
		created() {
			that = this;
			this.optionsTemp = this.options;
			if (this.type == 'rate' || this.type == 'score') {
				// this.optionsTemp = [{
				// 		label: '很不满意',
				// 		img: '',
				// 		isFill: false,
				// 		score: 1
				// 	},
				// 	{
				// 		label: '不满意',
				// 		img: '',
				// 		isFill: false,
				// 		score: 2
				// 	},
				// 	{
				// 		label: '一般',
				// 		img: '',
				// 		isFill: false,
				// 		score: 3
				// 	},
				// 	{
				// 		label: '满意',
				// 		img: '',
				// 		isFill: false,
				// 		score: 4
				// 	},
				// 	{
				// 		label: '很满意',
				// 		img: '',
				// 		isFill: false,
				// 		score: 5
				// 	}
				// ]
			}
		},
		mounted() {},
		methods: {
			setIdx(idx) {
				this.idx = idx;
			},
			isFillChange(e) {
				this.optionsTemp[this.idx].isFill = e.target.checked;
				this.$emit("update:options", this.optionsTemp);
			},
			isMustChange(e) {
				this.optionsTemp[this.idx].isMust = e.target.checked;
				this.$emit("update:options", this.optionsTemp);
			},
			add(idx) {
				let data = {
					label: '',
					img: '',
					isFill: false,
					isMust: false,
                    edit: true,
					score: 0
				};
				this.optionsTemp.splice(idx + 1,0,data)
				// this.optionsTemp.push()
			},
			minus(idx) {
				if (this.optionsTemp.length == 1) return;
				this.optionsTemp.splice(idx, 1);
			},
			up(idx) {
				if (idx == 0) return;
				let arr = this.optionsTemp;
				arr[idx] = arr.splice(idx - 1, 1, arr[idx])[0];

			},
			down(idx) {
				let arr = this.optionsTemp;
				arr[idx] = arr.splice(idx + 1, 1, arr[idx])[0];
			},
			labelChange(e) {
				this.$emit("update:options", this.optionsTemp);
			},
			chooseImg(idx) {
				let that = this;
				let input = document.createElement('input');
				input.value = '选择文件';
				input.type = 'file';
				input.accept = "image/gif,image/jpeg,image/jpg,image/png";
				input.onchange = event => {
					let file = event.target.files[0];
					let reader = new FileReader(); //实例化文件读取对象
					reader.readAsDataURL(file); //将文件读取为 DataURL,也就是base64编码
					reader.onload = function(ev) { //文件读取成功完成时触发
						let dataURL = ev.target.result; //获得文件读取成功后的DataURL,也就是base64编码
						that.upload(dataURL,file.name,idx);
					}
				};
				input.click();
			},
			upload(base64,name,idx) {
				let that = this;
				let data = {
					fileName: name,
					file: base64,
					fileType: 'questionPaper'
				}
				upload(data).then(res => {
					that.optionsTemp[idx].img = res.data.url;
					that.$emit("update:options", that.optionsTemp);
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	.options {
		width: 627px;
		// width: 577px;
		background-color: #F2F3F5;
		border-radius: 4px;
		padding: 12px;
		box-sizing: border-box;
		// margin: 0;
	}

	.optionTitle {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: 400;
		color: #262626;
		margin-bottom: 8px;
	}

	.text {
		width: 361px;
		text-align: left;
	}

	.img {
		width: 28px;
		margin-right: 20px;
	}

	.score {
		width: 40px;
		margin-right: 20px;
		text-align: center;

		input {
			padding: 0;
			text-align: center;
		}
	}

	.updown {
		width: 56px;
		margin-right: 20px;
	}

	.fill {
		width: 56px;
	}

	.con {
		background-color: #fff;
		padding: 14px;
		box-sizing: border-box;
		.option {
			margin-bottom: 12px;
		}
		.optionCon {
			display: flex;
			align-items: center;
			div {
				display: flex;
				align-items: center;

				img {
					width: 16px;
					height: 16px;
					margin-right: 10px;
					cursor: pointer;
				}

				img:last-child {
					margin-right: 0;
				}
			}

			.text {
				margin-right: 30px;

				input {
					width: 270px;
					height: 32px;
					margin-right: 12px;
				}
			}

			.fill {
				display: flex;
				justify-content: center;
			}
		}
		.warn {
			margin-top: 5px;
			font-size: 12px;
			color: #FC5260;
			animation-name: warnShow;
			animation-duration: 2s;
		}
		@keyframes warnShow {
		  0% {opacity: 1;}
		  50% {opacity: 0;}
		  100% {opacity: 1;}
		}
	}

	/deep/.ant-upload-list.ant-upload-list-text {
		display: none;
	}
</style>
