<template>
	<div class="buttons">
		<div class="button" @mouseenter="idx=1" @mouseleave="idx=0" v-if="showDel">
			<img v-if='idx == 1' src='@/assets/icon/del2.png' />
			<img v-if='idx != 1' src='@/assets/icon/del.png' />
			<span @click="buttonClick('del')">删除</span>
		</div>
		<div class="button" @mouseenter="idx=2" @mouseleave="idx=0">
			<img v-if='idx == 2' src='@/assets/icon/up2.png' />
			<img v-if='idx != 2' src='@/assets/icon/up.png' />
			<span @click="buttonClick('up')">上移</span>
		</div>
		<div class="button" @mouseenter="idx=3" @mouseleave="idx=0">
			<img v-if='idx == 3' src='@/assets/icon/down2.png' />
			<img v-if='idx != 3' src='@/assets/icon/down.png' />
			<span @click="buttonClick('down')">下移</span>
		</div>
		<div class="button" @mouseenter="idx=4" @mouseleave="idx=0">
			<img v-if='idx == 4' src='@/assets/icon/first2.png' />
			<img v-if='idx != 4' src='@/assets/icon/first.png' />
			<span @click="buttonClick('first')">最前</span>
		</div>
		<div class="button" @mouseenter="idx=5" @mouseleave="idx=0">
			<img v-if='idx == 5' src='@/assets/icon/last2.png' />
			<img v-if='idx != 5' src='@/assets/icon/last.png' />
			<span @click="buttonClick('last')">最后</span>
		</div>
	</div>
</template>
<script>
	/**
	 * @Author: Jenos
	 * @Date: 2021-09-17 15:08
	 * @LastEditTime: 2021-09-17 15:08
	 * @Description: 设置按钮组
	 * @prop {leftText,rightText} 分数左右的文字
	 */
	export default {
		name: "setbuttons",
		data() {
			return {
				idx: ''
			};
		},
		props: {
            showDel: { //是否显示删除按钮
				type: Boolean,
				default: true
			}
        },
		watch: {},
		computed: {},
		created() {},
		mounted() {},
		methods: {
			buttonClick(type) {
				this.$emit('btnClick', type)
			}
		},
	};
</script>

<style lang="less" scoped>
	.buttons {
		display: flex;
		flex-wrap: nowrap;
		justify-content: flex-end;

		// width: min-content;
		.button {
			margin-right: 5px;
			width: 60px;
			height: 28px;
			font-size: 12px;
			font-weight: 400;
			color: #777777;
			background: #FFFFFF;
			border-radius: 2px;
			border: 1px solid #EEEEEE;
			line-height: 26px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			img {
				width: 12px;
				height: 12px;
				margin-right: 5px;
			}
		}

		.button:hover {
			background: #00AAA6;
			color: #fff;
			border-color: #00AAA6;
		}

		button:last-child {
			margin-right: 0;
		}
	}
</style>
