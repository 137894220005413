<template>
	<div class="editor">
		<!-- radio -->
		<div class="radio" v-if='type == "radio"'>
			<div class="li title">
				<div class="left">
					标题:
				</div>
				<div class="right">
					<a-input type="text" placeholder="请输入" v-model='titleTemp' />
					<a-checkbox :checked='isMustTemp' @change="mustChange">必答</a-checkbox>
				</div>
			</div>
			<div class="li">
				<div class="left">
					说明:
				</div>
				<div class="right">
					<a-textarea type="text" placeholder="请输入" v-model='directionTemp' />
				</div>
			</div>
			<options :options.sync='optionsTemp'></options>
			<set-buttons :showDel='showDel' @btnClick='btnClick' class="buttons"></set-buttons>
		</div>

		<!-- checkbox -->
		<div class="checkbox" v-if='type == "checkbox"'>
			<div class="li title">
				<div class="left">
					标题:
				</div>
				<div class="right">
					<a-input type="text" placeholder="请输入" v-model='titleTemp' />
					<a-checkbox :checked='isMustTemp' @change="mustChange">必答</a-checkbox>
				</div>
			</div>
			<div class="li">
				<div class="left">
					说明:
				</div>
				<div class="right">
					<a-textarea type="text" placeholder="请输入" v-model='directionTemp' />
				</div>
			</div>
			<options :options.sync='optionsTemp'></options>
			<div class="selects">
				<a-select @change="minChange" :default-value="isEdit ?min :'至少选几项'">
					<a-select-option :value="i" v-for="(i,idx) in minList" :key='idx' :disabled='i > maxTemp'>
						{{i}}
					</a-select-option>
				</a-select>
				<a-select @change="maxChange" :default-value="isEdit ?max :'至多选几项'">
					<a-select-option :value="i" v-for="(i,idx) in maxList" :key='idx'>
						{{i}}
					</a-select-option>
				</a-select>
			</div>
			<set-buttons :showDel='showDel' @btnClick='btnClick' class="buttons"></set-buttons>
		</div>

		<!-- select -->
		<div class="select" v-if='type == "select"'>
			<div class="li title">
				<div class="left">
					标题:
				</div>
				<div class="right">
					<a-input type="text" placeholder="请输入" v-model='titleTemp' />
					<a-checkbox :checked='isMustTemp || hideMapOption' :disabled='hideMapOption' @change="mustChange">必答</a-checkbox>
					<a-checkbox v-if='showMap' class='map' :checked='isMap || hideMapOption' :disabled='hideMapOption' @change="mapChange">是否使用映射关系作为下拉选项
					</a-checkbox>
				</div>
			</div>
			<div class="li">
				<div class="left">
					说明:
				</div>
				<div class="right">
					<a-textarea type="text" placeholder="请输入" v-model='directionTemp' />
				</div>
			</div>
			<options v-if="!isMap" type='select' :options.sync='optionsTemp'></options>
			<set-buttons :showDel='showDel' v-if="!hideMapOption" @btnClick='btnClick' class="buttons"></set-buttons>
		</div>

		<!-- input -->
		<div class="input" v-if='type == "input"'>
			<div class="li title">
				<div class="left">
					标题:
				</div>
				<div class="right">
					<a-input type="text" placeholder="请输入" v-model='titleTemp' />
					<a-checkbox :checked='isMustTemp' @change="mustChange">必答</a-checkbox>
				</div>
			</div>
			<div class="li">
				<div class="left">
					说明:
				</div>
				<div class="right">
					<a-textarea type="text" placeholder="请输入" v-model='directionTemp' />
				</div>
			</div>
			<set-buttons :showDel='showDel' @btnClick='btnClick' class="buttons"></set-buttons>
		</div>

		<!-- textarea -->
		<div class="textarea" v-if='type == "textarea"'>
			<div class="li title">
				<div class="left">
					标题:
				</div>
				<div class="right">
					<a-input type="text" placeholder="请输入" v-model='titleTemp' />
					<a-checkbox :checked='isMustTemp' @change="mustChange">必答</a-checkbox>
				</div>
			</div>
			<div class="li">
				<div class="left">
					说明:
				</div>
				<div class="right">
					<a-textarea type="text" placeholder="请输入" v-model='directionTemp' />
				</div>
			</div>
			<set-buttons :showDel='showDel' @btnClick='btnClick' class="buttons"></set-buttons>
		</div>

		<!-- slider -->
		<div class="slider" v-if='type == "slider"'>
			<div class="li title">
				<div class="left">
					标题:
				</div>
				<div class="right">
					<a-input type="text" placeholder="请输入" v-model='titleTemp' />
					<a-checkbox :checked='isMustTemp' @change="mustChange">必答</a-checkbox>
				</div>
			</div>
			<div class="li">
				<div class="left">
					说明:
				</div>
				<div class="right">
					<a-textarea type="text" placeholder="请输入" v-model='directionTemp' />
				</div>
			</div>
			<div class="setting">
				<div class="con">
					<div class="line">
						<div class="l">
							<span>最小值</span>
							<a-input pattern="[0-9]*" @input="minTemp=minTemp.replace(/[^\d]/g,'')" placeholder="请输入"
								v-model='minTemp' />
						</div>
						<div class="r">
							<span>最小值显示文本</span>
							<a-input placeholder="请输入" v-model='minTextTemp' />
						</div>
					</div>
					<div class="line">
						<div class="l">
							<span>最大值</span>
							<a-input pattern="[0-9]*" @input="maxTemp=maxTemp.replace(/[^\d]/g,'')" placeholder="请输入"
								v-model='maxTemp' />
						</div>
						<div class="r">
							<span>最大值显示文本</span>
							<a-input placeholder="请输入" v-model='maxTextTemp' />
						</div>
					</div>
				</div>
			</div>
			<set-buttons :showDel='showDel' @btnClick='btnClick' class="buttons"></set-buttons>
		</div>

		<!-- rate -->
		<div class="rate" v-if='type == "rate"'>
			<div class="li title">
				<div class="left">
					标题:
				</div>
				<div class="right">
					<a-input type="text" placeholder="请输入" v-model='titleTemp' />
					<a-checkbox :checked='isMustTemp' @change="mustChange">必答</a-checkbox>
				</div>
			</div>
			<div class="li">
				<div class="left">
					说明:
				</div>
				<div class="right">
					<a-textarea type="text" placeholder="请输入" v-model='directionTemp' />
				</div>
			</div>
			<options type='rate' :options.sync='optionsTemp'></options>
			<div class="styles">
				<div class="con">
					<span>样式: </span>
					<a-icon type="star" theme="filled" :class='{active: styleIdx == 0}' @click='styleChange(0)' />
					<a-icon type="like" theme="filled" :class='{active: styleIdx == 1}' @click='styleChange(1)' />
					<span :class='{active: styleIdx == 2}' @click='styleChange(2)'>1</span>
				</div>
			</div>
			<set-buttons :showDel='showDel' @btnClick='btnClick' class="buttons"></set-buttons>
		</div>
	</div>
</template>
<script>
	/**
	 * @Author: Jenos
	 * @Date: 2021-09-17 15:08
	 * @LastEditTime: 2021-09-17 15:08
	 * @Description: 问卷编辑
	 * @prop {idx} 当前下标
	 * @prop {type} 问题类型 input输入框 textarea radio单选框 checkbox多选框 select rate slider
	 */
	import Options from '@/views/questionnaire/components/Options';
	import SetButtons from '@/views/questionnaire/components/SetButtons';
	export default {
		name: "question-editor",
		components: {
			Options,
			SetButtons
		},
		data() {
			return {
				optionsTemp: [],
				directionTemp: '', // 说明
				titleTemp: '', // 标题
				isMustTemp: false, // 是否必答
				isMapTemp: false, // 是否映射关系
				minTemp: 1, // 最小
				maxTemp: 1, // 最大
				minList: [0, 1],
				maxList: [0, 1],
				minTextTemp: '',
				maxTextTemp: '',
				styleIdx: 0,
				rateStyleTemp: 'star' // like num
			};
		},
		props: {
			idx: {
				type: Number,
				required: true
			},
			hideMapOption: {
				type: Boolean,
				default: false
			},
			type: {
				type: String,
				required: true
			},
			title: {
				type: String
			},
			direction: {
				type: String
			},
			isMust: {
				type: Boolean
			},
			isMap: {
				type: Boolean
			},
			min: {
				type: Number
			},
			max: {
				type: Number
			},
			minText: {
				type: String
			},
			maxText: {
				type: String
			},
			rateStyle: {
				type: String,
				default: 'star'
			},
			options: {
				type: Array
			},
			showMap: { //是否显示映射关系
				type: Boolean,
				default: true
			},
			isEdit: { //是否为编辑
				type: Boolean,
				default: false
			},
            showDel: { //是否显示删除按钮
				type: Boolean,
				default: true
			}

		},
		watch: {
			rateStyle(val) {
				if (this.rateStyle == 'star') {
					this.styleIdx = 0;
				}
				if (this.rateStyle == 'like') {
					this.styleIdx = 1;
				}
				if (this.rateStyle == 'num') {
					this.styleIdx = 2;
				}
				this.rateStyleTemp = val
			},
			rateStyleTemp(val) {
				this.$emit("update:rateStyle", val);
			},
			title(val) {
				this.titleTemp = val
			},
			titleTemp(val) {
				let data = {
					idx: this.idx,
					val
				}
				this.$emit("update:title", val);
			},
			direction(val) {
				this.directionTemp = val
			},
			directionTemp(val) {
				this.$emit("update:direction", val);
			},
			isMust(val) {
				this.isMustTemp = val
			},
			isMustTemp(val) {
				this.$emit("update:isMust", val);
			},
			isMap(val) {
				this.isMapTemp = val
			},
			isMapTemp(val) {
				this.$emit("update:isMap", val);
			},
			min(val) {
				this.minTemp = val;
			},
			minTemp(val) {
				this.$emit("update:min", Number(val));
			},
			minText(val) {
				this.minText = val;
			},
			minTextTemp(val) {
				this.$emit("update:minText", val);
			},
			max(val) {
				this.maxTemp = val;
			},
			maxTemp(val) {
				this.$emit("update:max", Number(val));
			},
			maxText(val) {
				this.maxTextTemp = val;
			},
			maxTextTemp(val) {
				this.$emit("update:maxText", val);
			},
			options(val) {
				this.$emit("update:options", val);
				this.optionsTemp = this.options;
				if (this.type == 'checkbox') {
					let minList = [];
					for (let i = 0; i <= val.length; i++) {
						minList.push(i);
					};
					this.minList = minList;
					this.maxList = minList;
				};
			},
			optionsTemp(val) {
				this.$emit("update:options", this.optionsTemp);
			}
		},
		computed: {},
		created() {
			this.titleTemp = this.title;
			this.directionTemp = this.direction;
			this.isMustTemp = this.isMust;
			this.isMapTemp = this.isMap;
			this.minTemp = this.min;
			this.maxTemp = this.max;
			this.minTextTemp = this.minText;
			this.maxTextTemp = this.maxText;
			this.rateStyleTemp = this.rateStyle
			if (this.rateStyle == 'star') {
				this.styleIdx = 0;
			}
			if (this.rateStyle == 'like') {
				this.styleIdx = 1;
			}
			if (this.rateStyle == 'num') {
				this.styleIdx = 2;
			}
			this.optionsTemp = this.options;
		},
		mounted() {
			if (this.type == 'checkbox') {
				let minList = [];
				for (let i = 0; i <= this.options.length; i++) {
					minList.push(i);
				};
				this.minList = minList;
				this.maxList = minList;
			};
		},
		methods: {
			mustChange(e) {
				this.isMustTemp = e.target.checked;
			},
			mapChange(e) {
				this.isMapTemp = e.target.checked;
				this.$emit('mapChange', {
					value: e.target.checked,
					idx: this.idx
				})
			},
			minChange(e) {
				this.minTemp = e;
				let list = [];
				for (let i of this.minList) {
					if (i >= e) {
						list.push(i)
					}
				};
				this.maxList = list;
			},
			maxChange(e) {
				this.maxTemp = e;
			},

			btnClick(e) {
				let data = {
					idx: this.idx,
					val: e
				};
				this.$emit('btnClick', data)
			},
			styleChange(idx) {
				this.styleIdx = idx;
				let styleType = '';
				if (idx == 0) {
					styleType = 'star'
				}
				if (idx == 1) {
					styleType = 'like'
				}
				if (idx == 2) {
					styleType = 'num'
				}
				this.rateStyleTemp = styleType;
				this.$emit("update:rateStyle", styleType);
			}
		},
	};
</script>

<style lang="less" scoped>
	.editor {
		width: 625px;
		padding-left: 24px;
		box-sizing: border-box;
		// background-color: #D3D4D6;
	}

	div {
		text-align: left;
	}

	.radio {}

	.li {
		margin-bottom: 16px;
		display: flex;
		align-items: center;
	}

	.left {
		font-size: 14px;
		font-weight: 500;
		color: #262626;
		margin-right: 20px;
	}

	.right {

		.ant-input {
			width: 270px;
			height: 32px;
			margin-right: 16px;
		}

		textarea.ant-input {
			width: 400px;
			height: 74px;
		}
	}

	.select {
		width: 577px;

		.right {
			display: flex;
			align-items: center;

			.ant-checkbox-wrapper {
				height: 42px;
				display: flex;
				align-items: center;
			}

			.map.ant-checkbox-wrapper {
				width: 166px;

				/deep/.ant-checkbox+span {
					width: 130px !important;
				}
			}
		}

	}

	.selects {
		display: flex;
		justify-content: flex-end;
		margin-top: 12px;

		.ant-select {
			margin-left: 8px;
			font-size: 12px;
			width: 105px;
			height: 28px;
			color: #777;
		}
	}

	.buttons {
		margin-top: 12px;
	}

	.setting {
		width: 577px;
		// height: 132px;
		background-color: #F2F3F5;
		border-radius: 4px;
		box-sizing: border-box;
		padding: 14px 12px;

		.con {
			padding: 20px 24px;
			background-color: #fff;
			border-radius: 2px;

			.line {
				display: flex;
				align-items: center;

				input {
					width: 100px;
				}

				span {
					font-size: 14px;
					color: #777777;
					margin-right: 10px;
				}

				.l {
					margin-right: 43px;
				}

			}

			.line:first-child {
				margin-bottom: 12px;
			}
		}
	}

	.styles {
		display: flex;
		justify-content: flex-end;
		margin-top: 12px;

		.con {
			width: 120px;
			height: 28px;
			border: 1px solid #EEEEEE;
			background-color: #fff;
			display: flex;
			align-items: center;
			padding-left: 8px;
			// box-sizing: border-box;
			font-size: 12px;
			color: #777777;

			i {
				color: #ccc;
				margin-left: 8px;
				font-size: 14px;
				cursor: pointer;
			}

			i.active {
				color: #00B7B3;
			}

			span:last-child {
				display: inline-block;
				width: 14px;
				height: 14px;
				margin-left: 8px;
				background-color: #fff;
				text-align: center;
				line-height: 14px;
				cursor: pointer;
			}

			span:last-child.active {
				background-image: linear-gradient(to right, #fff, #00B7B3);
			}
		}

	}
</style>
